<template>
<div>
  <b-container fluid>
    <div class="d-flex justify-content-center" v-if="loading"><div class="spinner"></div></div>
    <div v-if="showTeam">
      <b-row align-h='center'>
        <b-col cols='12' md='8'>
          <h2 class='team-title'>Import Players and Schedule</h2>
          <b-card>
            <div class="mb-3">
              <h4>Team Name</h4>
              <b-form-input v-model="team.name" :placeholder="team.name"></b-form-input>
              <small>Change the name to something more fun for you.</small>
            </div>
            <div class="mb-3">
              <h4>Players</h4>
              <b-alert variant="success" :show="!missingJerseyNumber && !missingGoalie && !missingGames">Looks like you have everything you need.  Please review and invite others to view and add stats!</b-alert>
              <b-alert variant="warning" :show="missingJerseyNumber">Some of your players are missing jersey numbers.  Please update right here, or in TeamSnap first and then refresh the page.</b-alert>
              <b-alert variant="warning" :show="missingGoalie">Looks like you don't have a goalie.  Please update positions right here, or in TeamSnap first and then refresh the page.</b-alert>
              <b-alert variant="warning" :show="goalieCount < 2">Looks like you only have one goalie.  If you're missing one, please update position right here, or in TeamSnap first and then refresh the page.</b-alert>
              <b-table responsive striped hover :items="team.players" :fields="playerFields" primary-key="name">
                <template #cell(jerseyNumber)="row">
                    <b-input type="number" class="form-control-sm"
                      style="width:5em"
                      v-model="row.item.jerseyNumber"
                      v-on:input="handleJerseyChange($event,row.item, row.index)"/>
                </template>
                <template #cell(position)="row">
                    <b-input type="text" class="form-control-sm"
                      style="width:5em"
                      v-model="row.item.position"
                      v-on:input="handlePositionChange($event,row.item, row.index)"/>
                </template>
              </b-table>

              <h3>Games</h3>
              <b-alert variant="warning" :show="missingGames">You're going to want some games scheduled.  Please add some in TeamSnap and then refresh the page.</b-alert>
              <b-table responsive striped hover :items="team.games" :fields="gameFields"></b-table>
              <h3>Invite Others</h3>
              <b-form-input disabled class='mb-2' id="input-1" v-model="invites.email1" type="email" required placeholder="Enter email"></b-form-input>
              <b-form-input disabled class='mb-2' id="input-2" v-model="invites.email2" type="email" required placeholder="Enter email"></b-form-input>
              <b-form-input disabled class='mb-2' id="input-3" v-model="invites.email3" type="email" required placeholder="Enter email"></b-form-input>

              <b-row align-h='center'>
                <b-col cols='12' md='8' class="d-flex justify-content-center">
                  <b-button :disabled="missingJerseyNumber || missingGoalie" class='mt-4 mb-5 mr-5' variant='primary' @click="saveTeam">Next</b-button>
                  <b-button class='mt-4 mb-5' variant='danger' @click='cancel'>Cancel</b-button>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div v-if="!isManager && !loading">
      <b-row align-h='center'>
        <b-col cols='12' md='8'>
          <b-card>
              <p>You need a team manager to link your team.  Shoot them an email with a link to this page and we'll have you set up in a jiffy.</p>
              <p><a href="/teamlist">Back to TeamList</a></p>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-container>

      <!-- <ul v-if="errors && errors.length">
        <li v-for="error of errors"
            :key="error.id">
          {{error.message}}
        </li>
      </ul> -->
</div>
</template>

<script>
import TeamSnap from '@/mixins/TeamSnap'
import moment from 'moment'
import { store } from '../store'

export default {
  name: 'TeamList',
  mixins: [TeamSnap],
  data () {
    return {
      team: {},
      teamInfo: {},
      playerFields: {},
      gameFields: {},
      users: [],
      userFields: {},
      missingJerseyNumber: false,
      missingGoalie: false,
      goalieCount: 0,
      missingGames: false,
      invites: {
        email1: '',
        email2: '',
        email3: ''
      },
      isManager: false,
      loading: true,
      showTeam: false,
      showSignup: false,
      errors: []
    }
  },
  computed: {
  },
  async created () {
    let tsTeamId = parseInt(this.$route.params.teamId)
    // await store.commit('setCurrentTeamId', teamId)
    // await store.dispatch('bindTeamData')
    // this.team = store.state.team[0]
    let tsUser = await this.getUserInfo()
    if (tsUser.managedTeamIds.includes(tsTeamId)) {
      this.isManager = true
      // Check if team exists already
      let team = await store.dispatch('getTeamByTsId', tsTeamId)
      if (team) {
        // Add the team to user profile manages
        if (!this.userProfile.manages.includes(team.id)) {
          this.userProfile.manages.push(team.id)
          this.userProfile.teams.push(team.id)
          await store.commit('setUserProfile', this.userProfile)
          await store.dispatch('updateUserProfile')
        }
        this.$router.push({
          name: 'TeamDetail',
          params: { teamId: team.id }
        })
      } else {
        this.setNav()
        this.buildForm(tsTeamId)
      }
    }
  },
  methods: {
    setNav () {
      store.commit('updateNav', {
        backLink: '/teamlist',
        teamLink: '/teamlist',
        teamName: 'Back to My Teams'
      })
    },
    buildForm(tsTeamId) {
      this.getTeamDetails(tsTeamId).then(team => {
        this.team = team
        this.showTeam = true
        this.showSignup = false
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
      this.playerFields = [
        {
          key: 'jerseyNumber',
          label: '#',
          'class': 'text-left'
        },
        {
          key: 'name',
          label: 'Player',
          'class': 'text-left text-nowrap'
        },
        {
          key: 'position',
          label: 'Position',
          'class': 'text-left text-nowrap'
        }
      ]
      this.gameFields = [
        {
          key: 'startDate',
          label: 'Date',
          formatter: (value) => {
            return moment(value).format('MMM D, YYYY')
          },
          sortable: true,
          'class': 'text-left text-nowrap'
        },
        {
          key: 'opponentName',
          label: 'Opponent',
          sortable: true,
          'class':
          'text-left text-nowrap'
        },
        {
          key: 'location',
          label: 'Location',
          sortable: true,
          'class': 'text-left text-nowrap'
        },
        {
          key: 'gameLabel',
          label: 'Label',
          sortable: true,
          'class': 'text-left text-nowrap d-none d-md-table-cell'
        }
      ]
    },
    handleJerseyChange (e, player, index) {
      let newNumber = parseInt(e)
      if (!isNaN(newNumber)) {
        this.team.players[index].jerseyNumber = newNumber
        this.team.players[index].missingJerseyNumber = false
        //Check if everyone has a jerseyNumber
        this.missingJerseyNumber = false
        this.team.players.forEach(player => {
          if (!player.jerseyNumber) {
            this.missingJerseyNumber = true
          }
        })
      }
    },
    handlePositionChange () {
      //Count for goalies
      this.goalieCount = 0
      this.team.players.forEach(player => {
        if (player.position === 'Goalie' || player.position === 'Goaltender') {
          this.goalieCount++
        }
      })
    },
    invite (e) {
      e.preventDefault()
    },
    cancel () {
      this.$router.push({
        name: 'AddTeam'
      })
    },
    async saveTeam () {
      if (this.team.tsId) {
        let defaultStats = [
          {
            name: 'goals',
            type: 'total',
            shortName: 'G',
            default: true
          },
          {
            name: 'shots',
            type: 'total',
            shortName: 'SHOT',
            default: true
          },
          {
            name: 'penalties',
            type: 'total',
            shortName: 'PEN',
            default: true
          },
          {
            name: 'powerplays',
            type: 'total',
            shortName: 'PP',
            default: true
          }
        ]

        this.team.statCategories = defaultStats

        let games = this.team.games

        delete this.team.games

        store.commit('setCurrentTeam', this.team)

        let teamId = await store.dispatch('addTeam')

        let gamePromises = []

        games.forEach(game => {
          game.teamId = teamId
          gamePromises.push(store.dispatch('addGame', game))
        })

        await Promise.all(gamePromises)

        let userProfile = { ...store.state.userProfile }

        this.userProfile.manages.push(teamId)
        this.userProfile.teams.push(teamId)
        store.commit('setUserProfile', userProfile)
        store.dispatch('updateUserProfile').then(() => {
          this.$router.push({
            name: 'teamsettings',
            params: { teamId: teamId },
            query: { from: 'addteam' }
          })
        }).catch(err => {
          console.log(err)
        })
      } else {
        console.log('Not a valid team.')
        this.loading = false
        this.showTeam = true
      }
    }
  }
}
</script>